// Import app.theme.scss
@import "./app.theme";
@import "./schema.scss";

html, body {
  margin: 0;
  padding: 0;

  .mat-menu-content .mat-icon {
    vertical-align: baseline;
    margin-right: auto;
  }
}

* {
  box-sizing: border-box;
  font-family: 'Visuelt Pro';
}

.error-contaner {
  background: ivory!important;
}

.snackbar-error {
    color: #d32f2f;
    background-color: #ffebee;
}

.snackbar-info {
    color: #0277bd;
    background-color: #e1f5fe;
}

.snackbar-success {
    color: #2e7d32;
    background-color: #c8e6c9;
}

.snackbar-warning {
    color: #ef6c00;
    background-color: #ffe0b2;
}
.form-field-hr {
  width: 100%;
  border-width: 2px;
  margin-top: -17px;
}