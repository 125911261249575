.json-schema-form {
  .ng-star-inserted {
    width: 100%!important;
  }

  flex-layout-section-widget > fieldset {
    margin-top: 24px;
    margin-bottom: 24px;

    & > legend {
      font-size: 1.1em;
      margin-bottom: 8px;
    }
  }

  .array-item {
    width: 100%;
  }

  .mat-button-base {
    width: 200px!important;
  }
}
